import DevTools from 'svelte-dev-tools';

function enableServer(value) {
  sessionStorage.setItem('pc.localServer', value);
  window.location.reload(false);
}

const tools = [
  {
    type: 'checkbox',
    title: 'enable Local Server',
    callback: enableServer,
    values: true,
  },
];

export const install = async function install() {
  // eslint-disable-next-line
  const devTools = new DevTools({
    target: document.body,
    props: {
      tools,
      env: process.env.NODE_ENV,
    },
  });
};
export { install as default };
